<template>
  <div class="article-box jcxx">
    <h3 style="margin-bottom: 15px;">信息维护</h3>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="first">
        <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="单位全称">{{
              this.list.nickName || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码">{{
              this.list.loginName || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="大会等级">
            <span v-if="this.list.plenaryLevel === 1"> 会员 </span>
            <span v-if="this.list.plenaryLevel === 2"> 理事 </span>
            <span v-if="this.list.plenaryLevel === 3"> 常务理事 </span>
            <span v-if="this.list.plenaryLevel === 4"> 副会长 </span>
            <el-tag size="small" @click="regarding(1, list.plenaryLevel)"> 变更申请</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="活动参与的分支机构">
          {{ detailInfo.partake.join(",") || "-" }}
          <el-tag size="small" @click="openFzjgForm(1, detailInfo.partakeId, detailInfo.followId)"> 变更申请</el-tag>
          <div style="font-size: 12px; color: #f56c6c">(该信息1年仅允许修改1次，请谨慎操作!)</div>
          </el-descriptions-item>

          <el-descriptions-item label="分支机构">
            <span v-if="this.list.orgLevel === 1"> 会员 </span>
            <span v-if="this.list.orgLevel === 2"> 理事 </span>
            <span v-if="this.list.orgLevel === 3"> 常务理事 </span>
            <span v-if="this.list.orgLevel === 4"> 副会长 </span>

            <el-tag size="small" @click="regarding(2, list.orgLevel)"> 变更申请</el-tag>
            <!-- <span v-if="showsss">已经申请过，请等待管理员审核</span> -->
          </el-descriptions-item>
          <el-descriptions-item label="关注的分支机构">
            {{ detailInfo.follow.join(",") || "-" }}
            <el-tag size="small" @click="openFzjgForm(2, detailInfo.followId, detailInfo.partakeId)"> 变更申请</el-tag>
            <div style="font-size: 12px; color: #f56c6c">(该信息1年仅允许修改1次，请谨慎操作!)</div>
          </el-descriptions-item>
        </el-descriptions>
        <div v-if="this.modification.show">
          <el-descriptions :column="2" border>
            <el-descriptions-item label="联系人">{{
                this.list.userName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{
                this.list.mobile || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="联系邮箱">{{
                this.list.email || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="快递地址">{{
                this.list.contactDeliveryAddress || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="邮编">{{
                this.list.contactZidCode || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="财务姓名">{{
                this.list.financeContactName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="财务电话">{{
                this.list.financeContactPhone || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="入会时间">{{
                this.list.initiationTime || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="入会通知">
              <FileEcho :fileList="this.list.fileList"></FileEcho>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div v-if="this.modification.sure">
          <el-form ref="form1" :model="po" class="demo-form-inline" label-width="100px">
            <div class="clearfix">
              <el-form-item class="form-item-3" label-width="120px" label="关注的分支机构" prop="name">
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-3" label="联系人" prop="userName">
                <el-input v-model="list.userName" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="联系电话" prop="mobile">
                <el-input v-model="list.mobile" placeholder="请输入联系电话"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="联系邮箱:" prop="email">
                <el-input v-model="list.email" placeholder="请输入联系邮箱"></el-input>
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-3" label="快递地址" :required="true">
                <el-input v-model="list.contactDeliveryAddress" placeholder="请输入快递地址"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="邮编" :required="true">
                <el-input v-model="list.contactZidCode" placeholder="请输入邮编"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="财务姓名" :required="true">
                <el-input v-model="list.financeContactName" placeholder="请输入财务姓名"></el-input>
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-3" label="财务电话" :required="true">
                <el-input v-model="list.financeContactPhone" placeholder="请输入财务电话"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <br>
        <div>
          <el-button type="primary" @click="modificationAdd(1)" v-if="modifications.show">修改</el-button>
          <div v-if="modifications.add">
            <el-button type="primary" @click="modificationAdd(2)">提交</el-button>
            <el-button type="primary" @click="modificationAddss">取消</el-button>
          </div>

        </div>
      </el-tab-pane>
      <el-tab-pane label="单位信息" name="second">
        <div v-if="this.nickName != '社团'">
          <el-descriptions title="" :column="2" border>
            <el-descriptions-item label="单位">{{
                this.nickName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="单位全称">{{
                this.getunitList.nickName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码">{{
                this.getunitList.loginName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="行业">
              <span v-if="this.getunVo.industryNature === 1"> 桥梁工程 </span>
              <span v-if="this.getunVo.industryNature === 2"> 隧道工程 </span>
              <span v-if="this.getunVo.industryNature === 3"> 基础设施 </span>
              <span v-if="this.getunVo.industryNature === 4"> 装备技术 </span>
              <span v-if="this.getunVo.industryNature === 5"> 运输综合 </span>
              <span v-if="this.getunVo.industryNature === 6"> 物流 </span>
              <span v-if="this.getunVo.industryNature === 7"> 
                其他（{{ this.getunVo.natureCounte }}）
              </span>
            </el-descriptions-item>
            <el-descriptions-item label="出资筹建单位">{{
                this.getunVo.funderUnit || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="业务经营范围">{{
                this.getunVo.businessScope || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="注册资金">{{
                this.getunVo.registeredFunds || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{
                this.getunVo.registeredTime || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="总资产">{{
                this.getunVo.totalFunds || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="年营业收入">{{
                this.getunVo.annualRevenue || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="资产负债率">{{ this.getunVo.assetRetio || "-" }}%</el-descriptions-item>
            <el-descriptions-item label="具有大专及以上学历的中高层管理人员比例">{{
                this.getunVo.personnelProportion || "-"
            }}%</el-descriptions-item>
            <el-descriptions-item label="通信地址">{{
                this.getunVo.communicateAddress || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="法人代表">{{
                this.getunVo.legalRepresentative || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="国籍">{{
                this.getunVo.nationality || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="役场">{{
                this.getunVo.unitNature || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="党组书记">{{
                this.getunVo.partySecretary || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="董事长">{{
                this.getunVo.chairman || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="总经理">{{
                this.getunVo.generalManager || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="总经理副">{{
                this.getunVo.deputyGeneralManager || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="网址">{{
                this.getunVo.url || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="微信公众号">{{
                this.getunVo.wechatOffcialNo || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="微博">{{
                this.getunVo.weibo || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="抖音">{{
                this.getunVo.tikTok || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="单位地址">{{ this.getunitList.codeNames
            }}{{ this.getunitList.specificAddress }}</el-descriptions-item>
            <el-descriptions-item label="单位简介">{{
                this.getunitList.unitIntroduction || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="单位意见">{{
                this.getunVo.unitOpinion || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="填表人">{{
                this.getunitList.preparer || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{
                this.getunitList.contactPhone || "-"
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="资料提交" :column="3" border v-if="getunitList">
            <el-descriptions-item label="单位简介" :span="3">{{ getunitList.introductory || '-'}}</el-descriptions-item>
            <el-descriptions-item label="单位信息加盖公章表" :span="3"><FileEcho :fileList="getunitList.unitPdfs"></FileEcho></el-descriptions-item>
            <!-- <el-descriptions-item label="代表单位和登记加盖公章表" :span="3"><FileEcho :fileList="getunitList.businessLicenses"></FileEcho></el-descriptions-item> -->
            <el-descriptions-item label="企业证书或法人登记" :span="3"><FileEcho :fileList="getunitList.businessLicenses"></FileEcho></el-descriptions-item>
            <el-descriptions-item label="其他加盖公章附件" :span="3"><FileEcho :fileList="getunitList.others"></FileEcho></el-descriptions-item>
          </el-descriptions>
          <!-- <div style="margin-top: 20px;">
            单位信息加盖公章表:
            <FileEcho :fileList="getunitList.businessLicenses"></FileEcho>
          </div>
          <div style="margin-top: 20px;">
            企业证书或法人登记:
            <FileEcho :fileList="getunitList.others"></FileEcho>
          </div>
          <div style="margin-top: 20px;">
            其他加盖公章附件:
            <FileEcho :fileList="getunitList.unitPdfs"></FileEcho>
          </div> -->
        </div>
        <div v-else>
          <el-descriptions title="" :column="2" border>
            <el-descriptions-item label="单位">
              {{ this.nickName || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label="社会团体名称">{{
                this.getunitList.nickName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="民政部（局）登记">{{
                this.getunitList.loginName || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="单位">{{
                this.getunitList.industryNature || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="形成时间">{{
                this.getityVo.unitEstablishTime || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="办公租所">{{
                this.getityVo.unitOfficeAddress || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="邮政编码">{{
                this.getityVo.contactsZipCode || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="电话">{{
                this.getityVo.contactsPhone || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="传真">{{
                this.getityVo.contactsFax || "-"
            }}</el-descriptions-item>

            <el-descriptions-item label="单位会员:">{{
                this.getityVo.memberUnitNumber || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="个人会员">{{
                this.getityVo.memberPersonNumber || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="分支机构">{{
                this.getityVo.institutionNumber || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="董事">{{
                this.getityVo.councilNumber || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="常务理事">{{
                this.getityVo.routineCouncilNumber || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="专职工作人员">{{
                this.getityVo.fullTimeNumber || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="智能手机">{{
                this.getityVo.unitPhone || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{
                this.getityVo.unitPhone || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="传真">{{
                this.getityVo.unitFax || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="办公地址">{{
                this.getityVo.unitOfficeAddress || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="邮政编码">{{
                this.getityVo.unitZipCode || "-"
            }}</el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="会长" :column="3" border v-if="itemDetail.unitCommunityVo.president">
            <template v-for="(item, index) in itemDetail.unitCommunityVo.president">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'president1' + index">
                <template slot="label">姓名</template>{{ item.name || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'president2' + index">
                <template slot="label">现职务</template>{{ item.nowPosition || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'president3' + index">
                <template slot="label">原职务</template>{{ item.pastPosition || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!itemDetail.unitCommunityVo.president.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="副会长" :column="3" border v-if="itemDetail.unitCommunityVo.vicePresident">
            <template v-for="(item, index) in itemDetail.unitCommunityVo.vicePresident">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'vicePresident1' + index">
                <template slot="label">姓名</template>{{ item.name || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'vicePresident2' + index">
                <template slot="label">现职务</template>{{ item.nowPosition || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'vicePresident3' + index">
                <template slot="label">原职务</template>{{ item.pastPosition || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!itemDetail.unitCommunityVo.vicePresident.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="秘书长" :column="3" border v-if="itemDetail.unitCommunityVo.secretary">
            <template v-for="(item, index) in itemDetail.unitCommunityVo.secretary">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'secretary1' + index">
                <template slot="label">姓名</template>{{ item.name || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'secretary2' + index">
                <template slot="label">现职务</template>{{ item.nowPosition || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'secretary3' + index">
                <template slot="label">原职务</template>{{ item.pastPosition || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!itemDetail.unitCommunityVo.secretary.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="副秘书长" :column="3" border v-if="itemDetail.unitCommunityVo.viceSecretary">
            <template v-for="(item, index) in itemDetail.unitCommunityVo.viceSecretary">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'viceSecretary1' + index">
                <template slot="label">姓名</template>{{ item.name || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'viceSecretary2' + index">
                <template slot="label">现职务</template>{{ item.nowPosition || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'viceSecretary3' + index">
                <template slot="label">原职务</template>{{ item.pastPosition || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!itemDetail.unitCommunityVo.viceSecretary.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="其他" :column="1" border>
            <el-descriptions-item label="备注">{{
                this.getunitList.unitNature || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="社团地址">{{ this.getunitList.codeNames }}
              {{ this.getunitList.specificAddress }}
            </el-descriptions-item>
            <el-descriptions-item label="填表人">{{
                this.getunitList.preparer || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{
                this.getunitList.contactPhone || "-"
            }}</el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="资料提交" :column="3" border v-if="getunitList">
            <el-descriptions-item label="单位简介" :span="3">{{ getunitList.introductory || '-'}}</el-descriptions-item>
            <el-descriptions-item label="单位信息加盖公章表" :span="3"><FileEcho :fileList="getunitList.unitPdfs"></FileEcho></el-descriptions-item>
            <!-- <el-descriptions-item label="代表单位和登记加盖公章表" :span="3"><FileEcho :fileList="getunitList.businessLicenses"></FileEcho></el-descriptions-item> -->
            <el-descriptions-item label="企业证书或法人登记" :span="3"><FileEcho :fileList="getunitList.businessLicenses"></FileEcho></el-descriptions-item>
            <el-descriptions-item label="其他加盖公章附件" :span="3"><FileEcho :fileList="getunitList.others"></FileEcho></el-descriptions-item>
          </el-descriptions>
          <!-- 单位信息加盖公章表:
          
          企业证书或法人登记:
          <FileEcho :fileList="getunitList.others"></FileEcho>
          其他加盖公章附件:
          <FileEcho :fileList="getunitList.unitPdfs"></FileEcho> -->
        </div>
      </el-tab-pane>

      <el-tab-pane label="代表单位信息" name="third">
        <!-- <div class="tioo">
          <h3>代表单位信息</h3>
          <p @click="fileShow">复函文件>></p>
        </div> -->

        <div class="content2" v-if="this.show">
          <el-descriptions :column="2" border>
            <el-descriptions-item label="代表单位姓名">{{
                this.getorganizationList.name || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="性别">
              <span v-if="this.getorganizationList.sex === 1">女 </span>
              <span v-if="this.getorganizationList.sex === 2">男 </span>
            </el-descriptions-item>
            <el-descriptions-item label="出生年月">{{
                this.getorganizationList.birthday || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="民族">{{
                this.getorganizationList.national || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="籍贯 ">{{
                this.getorganizationList.nativePlace || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="政治面貌">{{
                this.getorganizationList.politicsStatus || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="职务">{{
                this.getorganizationList.unitNature || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="职称">{{
                this.getorganizationList.title || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="学历">{{
                this.getorganizationList.degree || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="学位">{{
                this.getorganizationList.unitNature || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="手机">{{
                this.getorganizationList.phone || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="办公电话 :">{{
                this.getorganizationList.telephone || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="微信">{{
                this.getorganizationList.wechat || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="电子邮件">{{
                this.getorganizationList.unitNature || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="传真">{{
                this.getorganizationList.fax || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="通讯地址">{{
                this.getorganizationList.mailingAddress || "-"
            }}</el-descriptions-item>
            <el-descriptions-item label="邮政编码">{{
                this.getorganizationList.zipCode || "-"
            }}</el-descriptions-item>
          </el-descriptions>
          <!--  -->
          <div style="margin-top: 20px;">个人简历</div>
          <el-descriptions style="margin-top: 20px;" class="margin-top" title="" :column="3" border v-if="memberForResumes">
            <!-- <el-descriptions-item label="" :span="111">个人简历</el-descriptions-item> -->
            <template v-for="(item, index) in memberForResumes">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'time' + index">
                <template slot="label">起止年月</template>{{ item.time || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'unit' + index">
                <template slot="label">单位</template>{{ item.unit || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'position' + index">
                <template slot="label">职务</template>{{ item.position || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!memberForResumes.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions-item label=" 联系人姓名">{{
              this.getorganizationList.contactName || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label=" 职务 ">{{
              this.getorganizationList.contactPosition || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="传真">{{
              this.getorganizationList.contactFax || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="手机">{{
              this.getorganizationList.contactPhone || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="办公电话">{{
              this.getorganizationList.contactTelephone || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="微信">{{
              this.getorganizationList.contactWechat || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="Emai">{{
              this.getorganizationList.contactEmail || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="通讯地址">{{
              this.getorganizationList.contactMailingAddress || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="邮政编码">{{
              this.getorganizationList.contactZipCode || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="单位代表和联系人登记加盖公章表">
          </el-descriptions-item>

          <FileEcho :fileList="this.getorganizationList.unitReprsts"></FileEcho>
          <div class="buttom1">
            <el-button type="primary" @click="submit(1)" v-if="this.list.plenaryLevel === 1">修改</el-button>
            <el-button type="primary" @click="submit(5)" v-else>
              申请变更
            </el-button>
          </div>
        </div>

        <div class="content3" v-else>
          <el-form ref="form1" :model="po1" class="demo-form-inline" label-width="100px">
            <div class="clearfix">
              <el-form-item class="form-item-3" label-width="120px" label="单位代表姓名" prop="name">
                <el-input placeholder="请输入单位代表姓名" v-model="getorganizationList.name"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="性别" prop="sex">
                <el-radio-group v-model="getorganizationList.sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="form-item-3" label="出生年月" prop="birthday">
                <el-date-picker style="width: 100%" v-model="getorganizationList.birthday" type="date"
                  placeholder="年/月/日" />
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-3" label="民族" prop="national">
                <el-input v-model="getorganizationList.national" placeholder="请输入民族"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="籍贯" prop="nativePlace">
                <el-input v-model="getorganizationList.nativePlace" placeholder="请输入籍贯"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="政治面貌" prop="politicsStatus">
                <el-input v-model="getorganizationList.politicsStatus" placeholder="请输入政治面貌"></el-input>
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-3" label="职务" :required="true">
                <el-input v-model="getorganizationList.position" placeholder="请输入职务"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="职称" :required="true">
                <el-input v-model="getorganizationList.title" placeholder="请输入职称"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="学历" :required="true">
                <el-input v-model="getorganizationList.education" placeholder="请输入学历"></el-input>
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-3" label="学位" :required="true">
                <el-input v-model="getorganizationList.degree" placeholder="请输入学位"></el-input>
              </el-form-item>
              <el-form-item class="form-item-50" label="手机" :required="true">
                <el-input v-model="getorganizationList.phone" placeholder="请输入手机"></el-input>
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-50" label="办公电话" :required="true">
                <el-input v-model="getorganizationList.telephone" placeholder="请输入办公电话"></el-input>
              </el-form-item>
              <el-form-item class="form-item-50" label="微信" :required="true">
                <el-input v-model="getorganizationList.wechat" placeholder="请输入微信"></el-input>
              </el-form-item>
              <el-form-item class="form-item-50" label="Email" :required="true">
                <el-input v-model="getorganizationList.email" placeholder="请输入Email"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="传真" :required="true">
                <el-input v-model="getorganizationList.fax" placeholder="请输入传真"></el-input>
              </el-form-item>
            </div>

            <div class="clearfix">
              <el-form-item class="form-item-6" label="通讯地址" :required="true">
                <el-input v-model="getorganizationList.mailingAddress" placeholder="请输入通讯地址"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="邮政编码" :required="true">
                <el-input v-model="getorganizationList.zipCode" placeholder="请输入邮政编码"></el-input>
              </el-form-item>
            </div>

            <div>个人工作简历</div>
            <div class="form-item-from-box">
              <el-table border :data="getorganizationList.memberForResumes" highlight-current-row style="width: 100%">
                <el-table-column prop="time" label="起始年月" align="center" min-width="30%">
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center" min-width="40%">
                </el-table-column>
                <el-table-column prop="position" label="职务" align="center" min-width="15%">
                </el-table-column>
                <el-table-column prop="id" align="center" min-width="15%">
                  <template slot-scope="scope">
                    <span class="text-btn" @click="resumeItemClick(1, scope.$index)"><i class="el-icon-edit"></i></span>
                    <span class="text-btn danger" @click="resumeItemClick(2, scope.$index)"><i
                        class="el-icon-delete"></i></span>
                  </template>
                </el-table-column>
              </el-table>
              <el-button class="add-btn" type="primary" circle size="mini" icon="el-icon-plus"
                @click="resume.show = true">
              </el-button>
            </div>

            <div class="clearfix">
              <el-form-item class="form-item-3" label="联系人姓名" :required="true">
                <el-input v-model="getorganizationList.contactName" placeholder="请输入联系人姓名"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="职务" :required="true">
                <el-input v-model="getorganizationList.contactPosition" placeholder="请输入职务"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="传真" :required="true">
                <el-input v-model="getorganizationList.contactFax" placeholder="请输入传真"></el-input>
              </el-form-item>
            </div>

            <div class="clearfix">
              <el-form-item class="form-item-3" label="手机" :required="true">
                <el-input v-model="getorganizationList.contactPhone" placeholder="请输入手机"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="办公电话" :required="true">
                <el-input v-model="getorganizationList.contactTelephone" placeholder="请输入办公电话"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="微信" :required="true">
                <el-input v-model="getorganizationList.contactWechat" placeholder="请输入微信"></el-input>
              </el-form-item>
            </div>

            <div class="clearfix">
              <el-form-item class="form-item-3" label="Email" :required="true">
                <el-input v-model="getorganizationList.contactEmail" placeholder="请输入Email"></el-input>
              </el-form-item>
            </div>
            <div class="clearfix">
              <el-form-item class="form-item-6" label="通讯地址" :required="true">
                <el-input v-model="getorganizationList.contactMailingAddress" placeholder="请输入通讯地址"></el-input>
              </el-form-item>
              <el-form-item class="form-item-3" label="邮政编码" :required="true">
                <el-input v-model="getorganizationList.contactsZipCode" placeholder="请输入邮政编码"></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width="20px">
              <div>
                <span>*</span>
                注 请填写完成后
                <span class="text-btn">点击此处</span>
                下载pdf文件后签字并加盖公章，在第三部分资料上传进行提交
              </div>
            </el-form-item>
            <el-form-item label-width="200px" prop="unitPdfFile" label="企业信息表（加盖公章）">
            </el-form-item>
            <el-form-item>
              <UploadFile :config="uploadFileConfig1" @UploadFileFun="UploadFileFun1" />
            </el-form-item>
            <el-form-item label-width="500px" prop="businessLicenseFile" label="单位代表和联系人登记表（加盖公章，限10MB 以内的 PDF 文件）">
            </el-form-item>
          </el-form>
          <div class="buttom2">
            <el-button type="primary" @click="submit(2)">取消</el-button>
            <el-button type="primary" @click="submit(3)">保存</el-button>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="章程文件" name="fourth">
        <!-- <h3 style="font-size: 16px; margin-bottom: 20px;">章程文件</h3> -->
        <FileEcho :fileList="attFileList"></FileEcho>
      </el-tab-pane>
    </el-tabs>

    <!-- 代文变更 -->
    <el-dialog title="保存修改" :visible="amendClear.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="AmendClears">
      确定保存所修改的内容
      <span slot="footer">
        <el-button plain @click="AmendClears">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="AmendSubmits">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="取消保存" :visible="amendClear.show1" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="AmendClearss">
      确定取消保存所修改的内容？
      <span slot="footer">
        <el-button plain @click="AmendClearss">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="AmendSubmitssss">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 大会等级弹框 -->
    <el-dialog :title="titleDj" :visible="Khingan.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resumeClear2(1)">
      <el-radio-group v-model="Khingan.chgLevel" class="gg-radio">
        <el-radio :label="1" border>会员等级</el-radio>
        <el-radio :label="2" border>理事等级</el-radio>
        <el-radio :label="3" border>常务理事等级</el-radio>
        <el-radio :label="4" border >副会长等级</el-radio>
      </el-radio-group>
      <!-- <el-button type="primary" plain @click="KhinganchgLevel(1)">会员等级</el-button>
      <el-button type="primary" plain @click="KhinganchgLevel(2)">理事</el-button>
      <el-button type="primary" plain @click="KhinganchgLevel(3)">常务理事等级</el-button>
      <el-button type="primary" plain @click="KhinganchgLevel(4)">副会长等级</el-button> -->
      <span slot="footer">
        <el-button plain @click="resumeClear2(1)">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="resumeSubmit(1)">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提交确认" :visible="Khingan.srue" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resumeClear2(2)">
      <span>确定申请{{titleDj}}为{{ this.title }}吗？</span>
      <span slot="footer">
        <el-button plain @click="resumeClear2(2)">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="resumeSubmit(3)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 树枝等级弹框 -->
    <el-dialog title="变化大会等级" :visible="branch.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resumeClear2(1)">
      <el-button type="primary" plain @click="branchchgLevel(1)">会员等级</el-button>
      <el-button type="primary" plain @click="branchchgLevel(2)">理事等级</el-button>
      <el-button type="primary" plain @click="branchchgLevel(3)">常务理事等级</el-button>
      <el-button type="primary" plain @click="branchchgLevel(4)">副会长等级</el-button>
      <span slot="footer">
        <el-button plain @click="resumeClear2(1)">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="resumeSubmit(2)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提交确认" :visible="branch.srue" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resumeClear2(2)">
      <span>确定申请变更分支机构等级为{{ this.title }}吗？</span>
      <span slot="footer">
        <el-button plain @click="resumeClear2(2)">取消</el-button>
        <el-button type="primary" size="medium" @click="resumeSubmit(4)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加或修改工作简历 -->
    <el-dialog title="工作简历" :visible="resume.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resClear">
      <el-form ref="resumeForm" :model="resume.formData" class="demo-form-inline" label-width="100px"
        :rules="resume.rules">
        <el-form-item label="起止年月:" prop="time">
          <el-input v-model="resume.formData.time" placeholder="请输入起止年月"></el-input>
        </el-form-item>
        <el-form-item label="单位:" prop="unit">
          <el-input v-model="resume.formData.unit" placeholder="请输入分支机构名称"></el-input>
        </el-form-item>
        <el-form-item label="职务:" prop="position">
          <el-input v-model="resume.formData.position" placeholder="请输入职务"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="resClear">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="resSubmit()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 复函文件 -->
    <el-dialog title="复函文件" :visible="paper" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="Clear">
      <tr class="fisC">
        <td>时间</td>
        <td>文件</td>
      </tr>
      <tr v-for="item in this.fisList" :key="item.id">
        <td>{{ item.replyTime }}</td>
        <td>
          <FileEcho :fileList="item.fileVo"></FileEcho>
        </td>
      </tr>
    </el-dialog>

    <!-- 活动参与的分支机构 -->
    <el-dialog :title="fzjgForm.type == 1 ? '活动参与的分支机构' : '关注的分支机构'" :visible.sync="fzjgForm.dialogVisible" :modal-append-to-body='false' :close-on-click-modal="false" @close="closeFzjgForm">
      <el-form :model="fzjgForm" ref="fzjgForm"  class="demo-ruleForm">
        <el-form-item label="活动参与的分支机构" label-width="140px" v-if="fzjgForm.type == 1">
          <!-- @change="embranchmentIdChange($event, 'addForm1')" -->
          <el-select v-model="fzjgForm.id" clearable style="width: 100%" >
            <el-option v-for="(item, index) of fzjgForm.list" :key="index" :disabled="item.disabled" :label="item.nickName" :value="item.institutionsId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关注的分支机构" label-width="120px" v-if="fzjgForm.type == 2">
          <!-- @change="embranchmentIdChange($event, 'addForm1')" -->
          <el-select v-model="fzjgForm.ids" clearable style="width: 100%" multiple>
            <el-option v-for="(item, index) of fzjgForm.list" :key="index" :disabled="item.disabled" :label="item.nickName" :value="item.institutionsId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fzjgForm.dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="fzjgForm.loading" @click="submitFzjgForm" >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import FileEcho from "@c/upload/FileEcho.vue";
import UploadFile from "@c/upload/UploadFile.vue";
import TableRow from "@c/table/TableRow.vue";
import TableItem from "@c/table/TableItem.vue";

import { InstitutionModuleGet, checkleve, } from "@/http/api/InstitutionApi";
import {
  BasicList,
  UnitList,
  OrganizationList,
  ChangeList,
  BasisList,
  ReprstUp,
  replyFiles,
  GetInstitutionAllList,
  InitiationApiup,
} from "@/http/api/InitiationApi";

export default {
  name: "",
  props: {},
  components: { FileEcho, UploadFile, TableRow, TableItem },
  data () {
    return {
      // 修改活动参与的分支机构
      fzjgForm: {
        id: '',
        type: '',
        ids: [],
        dialogVisible: false,
        loading: false,
        list: []
      },
      itemDetail: {
        unitCommunityVo: {
          memberForCommPositionVos: [],
          president: [],
          vicePresident: [],
          secretary: [],
          viceSecretary: [],
        },
      },
      paper: false,
      resume: {
        shows: false,

        show: false,
        setIndex: -1,
        formData: {
          time: "",
          unit: "",
          position: "",
        },
        rules: {
          time: [
            { required: true, message: "请输入起始年月", trigger: "blur" },
          ],
          unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
          position: [
            { required: true, message: "请输入职务", trigger: "blur" },
          ],
        },
      },
      showsss: false,
      amendClear: {
        show: false,
        show1: false,
      },
      uploadFileConfig1: {
        limit: 1,
        callback: [],
      },
      show: true,
      activeName: "first",
      isshow: true,
      unitNature: "",
      nickName: "", //国企民营
      modifications: {
        show: true,
        add: false,
      },
      modification: {
        show: true,
        sure: false,
      },
      title: "",
      tabStatus: 1,
      attFileList: [],
      nowIndex: 1,
      detailInfo: {
        follow: [], //关注分支机构
        partake: [], //参与分支机构
      },
      po1: {
        birthday: "", //出生年月日 ,
        contactEmail: "", //联系人邮箱 ,
        contactFax: "", //联系人传真 ,
        contactMailingAddress: "", //联系人通讯地址 ,
        contactName: "", // 联系人姓名 ,
        contactPhone: "", //联系人手机 ,
        contactPosition: "", //联系人职务 ,
        contactTelephone: "", //联系人电话 ,
        contactWechat: "", //联系人微信 ,
        contactZipCode: "", //联系人邮政编码 ,
        degree: "", //学位 ,
        education: "", //学历 ,
        email: "", // 邮箱 ,
        fax: "", //传真 ,
        id: "", //id ,
        mailingAddress: "", // 通讯地址 ,
        memberForResumes: [], //个人工作简历集合 ,
        memberUnitId: "", // 单位会员主表id ,
        name: "", //姓名 ,
        national: "", //民族 ,
        nativePlace: "", // 籍贯 ,
        phone: "", //手机 ,
        politicsStatus: "", // 政治面貌 ,
        position: "", //职务 ,
        sex: "", //性别 1.男 2.女 ,
        telephone: "", //电话 ,
        title: "", // 职称 ,
        unitReprstFile: "", // 单位代表和联系人文件路径 ,
        wechat: "", // 微信 ,
        zipCode: "", // 邮政编码
      },
      poRules: [],
      tableData: [],
      list: [], //基础信息
      getunitList: [], //单位信息
      getunVo: [],
      getityVo: [],
      getorganizationList: [], //单位代表
      memberForResumes: [],
      Khingan: {
        show: false,
        chgLevel: "",
        srue: false,
      }, //大会等级
      branch: {
        show: false,
        chgLevel: "",
        srue: false,
      }, //树枝等级
      po: {
        // userName: "", // 用户姓名 | 1.单位注册:联系人姓名 2.个人注册:个人姓名 ,
        // financeContactZidCode: "", //常用联系人邮编 ,
        // position: "", // 职务 ,
        // registeredAddress: "", //注册地址 ,
        deliveryAddress: "", //快递地址 ,
        email: "", // 邮箱 | 1.单位注册:联系人邮箱 2.个人注册:个人邮箱 ,
        financeContactName: "", //财务联系人姓名 ,
        financeContactPhone: "", //财务联系人电话 ,
        mobile: "", //手机号 | 1.单位注册:联系人手机号 2.个人注册:个人手机号 ,
        unitName: "", //单位名称 ,
        zidCode: "", // 邮编
      },

      changeSelectStyle: "",
      fisList: [],
      titleDj: '',
      typeDj: ''
    };
  },
  beforeRouteEnter (to, from, next) {
    const user = window.localStorage.getItem("userPermissions");
    let Permissions = user?.toString();
    // console.log(Permissions);
    if (Permissions === "1") {
      next();
    } else {
      // next((vm) => {
      //   vm.show = true;
      // });
      next((vm) => {
        // vm.show = true;
        alert('请先入会')
        next("/apply/unit");
      });
    }
  },
  methods: {
    // 获取所有分支机构
    getInstitutionList() {
      GetInstitutionAllList().then((res) => {
        if (res.code === "000") {
          this.fzjgForm.list = res.result
          // this.embranchmentListqt = res.result
        }
      })
    },
    openFzjgForm(type, id, eid) {
      this.fzjgForm.type = type
      this.fzjgForm.list.forEach(item => {
        item.disabled = false
        eid.forEach(items => {
          if (item.institutionsId == items) {
            item.disabled = true
          }
        })
        // if (item.institutionsId == eid.join(',')) {
        //   item.disabled = true
        // }
      })
      if (type == 1) {
        this.fzjgForm.id = id.join(',')
        
      } else {
        this.fzjgForm.ids = id
      }
      console.log(this.fzjgForm.list)
      this.fzjgForm.dialogVisible = true
    },
    submitFzjgForm() {
      const params = {
        memberId: this.list.memberId, // id
        type: this.fzjgForm.type,
        memberInstitutions: []
      }
      if (this.fzjgForm.type == 1) {
        if (!this.fzjgForm.id) return this.$message({ type: 'error', message: '请选择分支机构'})
        params.memberInstitutions = [{ institutionsId: this.fzjgForm.id, type: 1 }]
      } else {
        this.fzjgForm.ids.forEach(item => {
          params.memberInstitutions.push({ institutionsId: item, type: 2 })
        })
      }
      this.fzjgForm.loading = true
      InitiationApiup(params).then(res => {
        this.fzjgForm.loading = false
        if (res.code == '000') {
          this.getList()
          this.fzjgForm.dialogVisible = false
          this.$message({
              type: "success",
              message: "修改成功！！！",
            });
        }
      })
    },
    closeFzjgForm() {
      this.fzjgForm.id = ''
      this.fzjgForm.ids = []
      this.fzjgForm.type = ''
    },
    // 新end
    Clear () {
      this.paper = false;
    },
    AmendClears () {
      this.amendClear.show = false;
    },
    AmendClearss () {
      this.amendClear.show1 = false;
      this.show = true;
    },
    AmendSubmits () {
      const params = {
        birthday: this.getorganizationList.birthday, //出生年月日 ,
        contactEmail: this.getorganizationList.contactEmail, //联系人邮箱 ,
        contactFax: this.getorganizationList.contactFax, //联系人传真 ,
        contactMailingAddress: this.getorganizationList.contactMailingAddress, //联系人通讯地址 ,
        contactName: this.getorganizationList.contactName, // 联系人姓名 ,
        contactPhone: this.getorganizationList.contactPhone, //联系人手机 ,
        contactPosition: this.getorganizationList.contactPosition, //联系人职务 ,
        contactTelephone: this.getorganizationList.contactTelephone, //联系人电话 ,
        contactWechat: this.getorganizationList.contactWechat, //联系人微信 ,
        contactZipCode: this.getorganizationList.contactZipCode, //联系人邮政编码 ,
        degree: this.getorganizationList.degree, //学位 ,
        education: this.getorganizationList.education, //学历 ,
        email: this.getorganizationList.email, // 邮箱 ,
        fax: this.getorganizationList.fax, //传真 ,
        id: this.getorganizationList.id, //id ,
        mailingAddress: this.getorganizationList.mailingAddress, // 通讯地址 ,
        memberForResumes: this.getorganizationList.memberForResumes, //个人工作简历集合 ,
        // memberForResumes: [{unit: '12', time: '12', positon: '22'}], //个人工作简历集合 ,
        memberUnitId: this.getorganizationList.memberUnitId, // 单位会员主表id ,
        name: this.getorganizationList.name, //姓名 ,
        national: this.getorganizationList.namnationale, //民族 ,
        nativePlace: this.getorganizationList.nativePlace, // 籍贯 ,
        phone: this.getorganizationList.phone, //手机 ,
        politicsStatus: this.getorganizationList.politicsStatus, // 政治面貌 ,
        position: this.getorganizationList.position, //职务 ,
        sex: this.getorganizationList.sex, //性别 1.男 2.女 ,
        telephone: this.getorganizationList.telephone, //电话 ,
        title: this.getorganizationList.title, // 职称 ,
        unitReprstFile: this.getorganizationList.unitReprstFile, // 单位代表和联系人文件路径 ,
        wechat: this.getorganizationList.wechat, // 微信 ,
        zipCode: this.getorganizationList.zipCode, // 邮政编码
      };
      ReprstUp(params).then((res) => {
        if (res.code === "000") {
          this.amendClear.show = false;
          this.show = true;
          this.$message.success("修改成功");
          this.$nextTick(() => {
      this.getInstitutionList()

      this.getList();
      this.getUnitLists();
      this.getModel();
      this.getListtt();
      // unitEstablishTime
    });
        }
      });
    },
    AmendSubmitssss () {
      this.amendClear.show1 = false;
    },
    //     // 点击工作简历item
    resumeItemClick (flag, index) {
      if (flag === 1) {
        // 编辑
        const obj = this.po1.memberForResumes[index];
        this.resume.formData.time = obj.time;
        this.resume.formData.unit = obj.unit;
        this.resume.formData.position = obj.position;
        this.resume.setIndex = index;
        this.resume.show = true;
      } else {
        // 删除
        this.$confirm(`确定要删除该条工作简历吗？`, "确认删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.po1.memberForResumes.splice(index, 1);
          this.$message.success("删除成功");
        });
      }
    },
    // 提交工作经历
    resSubmit () {
      this.$refs.resumeForm.validate((bool) => {
        if (bool) {
          if (this.resume.setIndex !== -1) {
            this.po1.memberForResumes[this.resume.setIndex].time =
              this.resume.formData.time;
            this.po1.memberForResumes[this.resume.setIndex].unit =
              this.resume.formData.unit;
            this.po1.memberForResumes[this.resume.setIndex].position =
              this.resume.formData.position;
          } else {
            this.po1.memberForResumes.push({
              time: this.resume.formData.time,
              unit: this.resume.formData.unit,
              position: this.resume.formData.position,
            });
          }
          this.resClear();
        }
      });
    },
    // 关闭工作简历弹窗
    fileShow () {
      this.paper = true;
      replyFiles({
        id: this.po1.memberUnitId,
      }).then((res) => {
        this.fisList = res.result;
      });
    },
    resClear () {
      this.resume.formData = {
        time: "",
        unit: "",
        position: "",
      };
      this.resume.setIndex = -1;
      this.resume.show = false;
    },

    UploadFileFun1 (val) {
      this.getorganizationList.unitReprstFile = val;
    },
    submit (num) {
      if (num === 1) {
        this.show = false;
      } else if (num === 3) {
        this.amendClear.show = true;
      } else if (num === 2) {
        this.amendClear.show1 = true;
      } else if (num === 5) {
        ChangeList({
          chgLevel: 1,
          type: 1,
        }).then((res) => {
          if (res.code === "000") {
          }
        });
      }
    },
    handleClick (tab, event) { },
    // 基础信息修改
    modificationAdd (num) {
      if (num === 1) {
        (this.modification.show = false), (this.modification.sure = true);
        this.modifications.show = false;
        this.modifications.add = true;
      } else if (num === 2) {
        BasisList({
          contactDeliveryAddress: this.list.contactDeliveryAddress,
          email: this.list.email,
          financeContactName: this.list.financeContactName,
          financeContactPhone: this.list.financeContactPhone,
          mobile: this.list.mobile,
          userName: this.list.userName,
          contactZidCode: this.list.contactZidCode,
        }).then((res) => {
          if (res.code === "000") {
            (this.modification.show = true), (this.modification.sure = false);
            this.modifications.show = true;
            this.modifications.add = false;
            this.$message({
              type: "success",
              message: "修改成功！！！",
            });
          }
        });
      }
      //  else if (num === 3) {
      //   // (this.modification.show = true), (this.modification.sure = false);
      //   this.modifications.show = true;
      //   // this.modifications.add = false;
      // }
    },
    modificationAddss () {
      this.modifications.show = true;
      this.modifications.add = false;
      this.modification.sure = false
      this.modification.show = true
    },
    resumeSubmit (num) {
      if (num === 1) {
        this.Khingan.srue = true;
        this.Khingan.show = false;
      } else if (num === 3) {
        ChangeList({
          chgLevel: this.Khingan.chgLevel,
          type: this.typeDj,
        }).then((res) => {
          if (res.code === "000") {
            this.$message.success("提交成功！");
          } else {
            this.showsss = true;
          }
        });
        this.Khingan.srue = false;
      } else if (num === 2) {
        this.branch.srue = true;
        this.branch.show = false;
      } else if (num === 4) {
        ChangeList({
          chgLevel: this.branch.chgLevel,
          type: 2,
        }).then((res) => {
          if (res.code === "000") {
            console.log(res, 222);
          }
        });
        this.branch.srue = false;
      }
    },
    resumeClear2 (num) {
      if (num === 1) {
        this.Khingan.show = false;
        this.branch.show = false;
      } else {
        this.Khingan.srue = false;
        this.branch.srue = false;
      }
    },
    KhinganchgLevel (num) {
      if (num === 1) {
        this.Khingan.chgLevel = 1;
        this.title = "会员等级";
      } else if (num === 2) {
        this.Khingan.chgLevel = 2;
        this.title = "理事等级";
      } else if (num === 3) {
        this.Khingan.chgLevel = 3;
        this.title = "常务理事等级";
      } else if (num === 4) {
        this.Khingan.chgLevel = 4;
        this.title = "副会长等级";
      }
    },

    branchchgLevel (num) {
      if (num === 1) {
        this.branch.chgLevel = 1;
        this.title = "会员等级";
      } else if (num === 2) {
        this.branch.chgLevel = 2;
        this.title = "管理者等级";
      } else if (num === 3) {
        this.branch.chgLevel = 3;
        this.title = "常务理事等级";
      } else if (num === 4) {
        this.branch.chgLevel = 4;
        this.title = "副会长等级";
      }
    },
    regarding (num, type) {
      this.typeDj = num
      this.Khingan.chgLevel = type
      // this.branch.chgLevel = type
      if (num === 1) {
        checkleve({
          type: 1,
        }).then((res) => {
          if (res.result === true) {
            this.titleDj = '变更大会等级'
            this.Khingan.show = true;
            
          } else {
            this.$message.warning('申请中，请等待管理员确认。。。')
          }
        });
      } else {
        checkleve({
          type: 2,
        }).then((res) => {
          if (res.result === true) {
            this.titleDj = '变更分支机构等级'
            this.Khingan.show = true;
          } else {
            this.$message.warning('申请中，请等待管理员确认。。。')
          }
        });
      }
    },

    getModel () {
      InstitutionModuleGet({ module: 5, type: 2 }).then((res) => {
        if (res.code === "000") {
          if (res.result && res.result.length > 0) {
            this.attFileList.push(res.result[0].fileInfo);
          }
        }
      });
    },
    getList () {
      BasicList().then((res) => {
        this.loading = true;
        if (res.code === "000") {
          if (res.result != null) {
            if (res.result.initiation) {
              res.result.fileList = [res.result.initiation]
            }
            this.list = res.result;
            // 关注的分支机构
            this.detailInfo.followId = res.result.memberInstitutions.filter(item => { return item.type == 2 }).map(item => {
              return item.institutionsId
            })
            this.detailInfo.follow = res.result.memberInstitutions
              .filter((f) => {
                if (f.type == 2) {
                  return f;
                }
              })
              .map((s) => {
                return s.nickName;
              });
            // 参与的分支机构
            this.detailInfo.partakeId = res.result.memberInstitutions.filter(item => { return item.type == 1 }).map(item => {
              return item.institutionsId
            })
            this.detailInfo.partake = res.result.memberInstitutions.filter((f) => {
                if (f.type == 1) {
                  return f;
                }
              })
              .map((s) => {
                return s.nickName;
              });
          }
        }
      });
    },
    getUnitLists () {
      this.loading = true;
      UnitList().then((res) => {
        if (res.code === "000") {
          this.loading = false;
          this.getunitList = res.result || [];
          console.log(this.getunitList, "getunitList");
          this.getunVo = res.result.unitEnterpriseVo || [];

          if (res.result.unitNature === 1) {
            this.nickName = "国企";
          } else if (res.result.unitNature === 2) {
            this.nickName = "事业";
          } else if (res.result.unitNature === 3) {
            this.nickName = "合资";
          } else if (res.result.unitNature === 4) {
            this.nickName = "民营";
          } else if (res.result.unitNature === 5) {
            this.nickName = "社团";
            this.getityVo = res.result.unitCommunityVo || [];

            this.itemDetail.unitCommunityVo.president =
              res.result.unitCommunityVo.memberForCommPositionVos.filter(
                (item) => {
                  if (item.type == 1) {
                    return item;
                  }
                }
              );
            this.itemDetail.unitCommunityVo.vicePresident =
              res.result.unitCommunityVo.memberForCommPositionVos.filter(
                (item) => {
                  if (item.type == 2) {
                    return item;
                  }
                }
              );
            this.itemDetail.unitCommunityVo.secretary =
              res.result.unitCommunityVo.memberForCommPositionVos.filter(
                (item) => {
                  if (item.type == 3) {
                    return item;
                  }
                }
              );
            this.itemDetail.unitCommunityVo.viceSecretary =
              res.result.unitCommunityVo.memberForCommPositionVos.filter(
                (item) => {
                  if (item.type == 4) {
                    return item;
                  }
                }
              );
          } else if (res.result.unitNature === 6) {
            this.nickName = "其他";
          }
        }
      });
    },
    getListtt () {
      OrganizationList().then((res) => {
        if (res.code === "000") {
          this.loading = false;
          this.getorganizationList = res.result || [];
          this.memberForResumes = res.result.memberForResumes || [];
          console.log(this.getorganizationList, "getorganizationList");

          // this.po1.memberUnitId = res.result.memberUnitId || [];
        }
      });
    },
  },
  created () {
    this.$nextTick(() => {
      this.getInstitutionList()

      this.getList();
      this.getUnitLists();
      this.getModel();
      this.getListtt();
      // unitEstablishTime
    });
  },
};
</script>

<style lang="less">
.jcxx .el-descriptions-item__label.is-bordered-label {
  width: 180px;
}
.jcxx .el-descriptions-item__content {
  width: 300px;
}
.gg-radio {
  .el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 0;
  }
  .el-radio {
    margin-bottom: 10px;
    .is-bordered +.el-radio.is-bordered {
      
    }
  }
}
</style>
<style lang="less" scoped>


.tioo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  h3 {
    font-size: 16px;
  }

  p {
    color: #1e63b0;
  }
}

.form-apply {
  width: 1200px;
  margin: auto;
}

.input {
  width: 150px;
  margin-right: 20px;
}

.title {
  text-align: center;
  font-size: 20px;
  padding: 30px 0 10px 0;
}

.form-box {
  padding: 50px 100px;
}

.form-item-from-box {
  position: relative;
  padding-bottom: 20px;
}

.add-btn {
  position: absolute;
  top: 10px;
  right: 50px;
}

.state-title {
  padding: 100px 0;
  text-align: center;
}

.record-box {
  width: 800px;
  margin: auto;
}

.record-list {
  max-height: 600px;
  overflow-y: auto;
}

.fisC {
  width: 100%;
  display: flex;
  justify-content: space-around;

  td:nth-child(1) {
    width: 25%;
  }

  td:nth-child(2) {
    width: 75%;
  }
}
</style>